.cors_wrap{
  max-width: 700px; 
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin-right: auto;
  margin-left: auto;  

}

.cors_wrap > button > span > svg {
  font-size: 1.9rem;
}
.cors_wrap > button{
  height: 70px;

  width: 70px;
}
.cors_wrap > #scrollmenu {
  text-align: center;
  width: 100%;
  height: 100%;
  cursor: -webkit-grab; cursor: grab;
  overflow: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  margin: 0 auto;
}

.cors_wrap > #scrollmenu div {

  display: inline-block;
  width: 4em;
  height: 4em;
  margin: .4rem;

  text-decoration: none;
  cursor: pointer;

  -webkit-transition: all 0.1s cubic-bezier(1,.99,0,.01);
  -moz-transition: all 0.1s cubic-bezier(1,.99,0,.01);
  transition: all 0.1s cubic-bezier(1,.99,0,.01);

}

.cors_wrap > #scrollmenu > div.cormod {

  background-repeat: no-repeat;
  background-position: center;
  background-image: url("https://assets.playprostar.com/images/Provn1/data/cuero.png");
  background-blend-mode: multiply;  

}

.cors_wrap > #scrollmenu > div[data-selected=false]:hover {
  border: 1px;
  border-style: solid;
  border-color: black;   
  transform: scale(1.10);
  margin-left: .7em; 
  margin-right: .7em;
}

.cors_wrap > #scrollmenu > div[data-selected=true]{
  border: 1px;
  border-style: solid;
  border-color: black; 
  position: relative;
  margin-top: .7em;
  transform: scale(1.10);
}



.cors_wrap > #scrollmenu > div[data-selected=true] > .activeIcon {
  background-color: rgba(246.3,42.8,42.8, 0.8);
  border-bottom-left-radius: 50%;
  height: 20px;
  width: 25px;
  top: 0;
  right: 0;
  position: absolute;
}

.cors_wrap > #scrollmenu > div[data-selected=true] > .activeIcon > span {
  color: #fff;
  position: absolute;
  width: 13px;
  height: 7px;
  right: 5px;
  top: 3px;
  border-bottom: solid 1px currentColor;
  border-left: solid 1px currentColor;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

/********************Images**************/

.cors_wrap > #scrollmenu > div > img{
    height: 100%;
    width: 100%;
}

.cors_wrap > #scrollmenu > div.flagmod{
     width: 5em !important;
     height: 3.1em !important;
}