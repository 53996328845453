.menu{
  color: white;
}


.list{
  width: 280px;
}
.control{
  position: relative;
  flex-grow: 1;
  height:55px;
  width: 100%;
}
.control > .logo_bar{
  position: absolute;
  height: 55px;
  width: 60px;
  z-index: 999;
}
.control > .logo_sidebar{
  position: absolute;
  height: 55px;
  width: 100%;
  z-index: 999; 
}

.logo{ 
  background-repeat: no-repeat;
  background-size: 85px;
  background-position: center;
  width: 100%;
  height: 100%;
}
.menuButton{
  margin-left: -12px;
  margin-right: 20px;
}