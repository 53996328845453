/*----------------------- Preloader -----------------------*/

.preloader-wrapper {

    position: fixed;
    background: #ffc300;
    width:100%;
    height: 100%;
    z-index: 99999;    
}

.logo_loader{ 
  background-repeat: no-repeat;
  background-size: 85px;
  background-position: center;
  width: 100%;
  height: 100%;
}

.preloader-wrapper > .logo_loader {

  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 6px; 


}

.lds-dual-ring {
  display: inline-block;
  position: absolute;
  width: 100px;
  height: 100px;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;   

}
.lds-dual-ring:after {
  content: " ";
  display: block;
  padding: 45px;

  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}