.selector{
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selector > div{
    background: transparent!important;
}

.cmd1{
    margin: 20px!important;
}
