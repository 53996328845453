  .Site-content > .main_pucharse .layer_selector{
    display: flex;
    flex-grow: 1;
    height: 424px;
    justify-content: flex-end;
  }/*
  
  .Site-content > .main_pucharse .layer_selector >  #scrollayermenu > .MuiTabs-scrollable{
    display: flex;
    justify-content: center;
    flex-direction: column;
    /*align-items: center;*/
  /*}*/
  
  .Site-content > .main_pucharse .layer_selector >  .scrollayermenu  > .MuiTabs-scrollable > div > button{
    line-height: 50px;
  
  
    color:#000;
    opacity:.6;
    -webkit-transition:opacity .1s;
    transition:opacity .1s;
    padding: 0!important;



    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-weight: 100;
    font-stretch: ultra-condensed;
    font-size: 20.3333px;
    font-family: Arial;
  }
  
  .Site-content > .main_pucharse .layer_selector >  .scrollayermenu  > .MuiTabs-scrollable > div > button[aria-selected=true],
  .Site-content > .main_pucharse .layer_selector >  .scrollayermenu  > .MuiTabs-scrollable > div > button:hover  {
    opacity:1
  }
  
  .Site-content > .main_pucharse .layer_selector >  .scrollayermenu  > .MuiTabs-scrollable > div > button[aria-selected=true]{
    font-weight:700
  }

