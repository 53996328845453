body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-shadow: inset 0px 0px 201px 128px rgba(66,66,66,0.48);
  -moz-box-shadow: inset 0px 0px 201px 128px rgba(66,66,66,0.48);
  box-shadow: inset 0px 0px 201px 128px rgba(66,66,66,0.48); 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  display: flex;
  min-height: 100vh;
  flex-direction: column;  
}

.purechat-poweredby-container{
  display: none !important;
}