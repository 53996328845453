
.Site-content{
    width: 100%;
    display: flex;
    justify-content: center;    
}

.Site-content > div{

}

.Site-content > .main_builder{
    width: 75%;
}

.Site-content > .main_pucharse{
    text-align: right;
    width: 15%;
}

.Site-content > .main_pucharse > .card > div[data-name=ProductName]{
  font-size: 26px;
}

.Site-content > .main_pucharse > .card > div[data-name=ProductPrice]{
  font-size: 20px;
}

.Site-content > .main_pucharse > .card > .ButtonPucharse > button{
  background-color: rgb(244, 67, 54);  
  color: rgb(255, 255, 255);
}

.Site-content > .main_pucharse > .card > .ButtonSummary > button{
    color: rgb(244, 67, 54);
}

.Site-content > .main_pucharse > .card > .ButtonSummary > button > span > svg[role=presentation]{
  font-size: 1rem !important;
}

.Site-content > .main_otherproducts{
    width: 5%;
    color: #fff;  
}

.Site-content > .main_otherproducts > .card{
  height: 80vh; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.Site-content > .main_otherproducts > .card > #angle-picker{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.acttive > span{
  opacity: 1;
  color: blue;
}

.paper-layer-0{
  flex-grow: 1;
}

.Content_canvas{
  position: relative;
  width: calc(100%);
  min-height: 70vh;
}

@media screen and (max-width: 600px) {
.Content_canvas{
  min-height: 60vh;
}  
}

.Content_canvas{
  display: flex;
}

#angle-picker{
  width: 104px;
  
}

#angle-picker[data-active=false]{
  display: none;
}

#angle-picker > div{
  width: 55px;
  height: 55px;
  color: #e6e6e6;
  padding: 10px;
  cursor: pointer;
}

#angle-picker > div > .iconpicker{
  width: 100%;
  height: 50px;
}

#angle-picker > div > .iconpicker[data-selected=false]{
  opacity: .6;
}

#angle-picker > div > .iconpicker[data-selected=true]{
  opacity: 1;
}

.card{
  /*background-color:rgba(0, 0, 0, 0.40);*/
  margin: 8px;
  border-radius: 1px;
}
canvas {

   position:absolute;
   max-width: 100%;
   max-height: 100%;

  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;

}

/****************************LOADER**********************************/

.off{
  display: none;
}
.Loader-wrapper{
    position: absolute;
    width:100%;
    height: 100%;
    z-index: 31;

}

.spinner {
  position: absolute;
  width: 75px;
  height: 75px;
  background-color: #ffc300;
  border-radius: 100%;  

  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;  

  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

/****************************LOADER FIN*****************************/